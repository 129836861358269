import React from "react";
import styles from "./AlertCellWithPopover.style.less";
import { Popover } from "@/components/DesignSystem";
import { ReactComponent as ExclamationCircle } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-circle.svg";
import { ReactComponent as ExclamationCircleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-circle-solid.svg";
import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle-solid.svg";
import PropTypes from "prop-types";

const TYPE = {
    CRITICAL: "critical",
    ERROR: "error",
    WARNING: "warning",
};

export const AlertCellWithPopover = ({
    type = TYPE.ERROR,
    popoverTitle,
    popoverContent,
    popoverVisible = !!popoverTitle || !!popoverContent,
    iconVisible = false,
    content = null,
    contentBefore = null,
}) => {
    const IconComponent =
        type === TYPE.ERROR
            ? ExclamationCircle
            : type === TYPE.CRITICAL
            ? ExclamationCircleSolid
            : ExclamationTriangleSolid;
    const icon = (
        <IconComponent
            className={
                [TYPE.ERROR, TYPE.CRITICAL].includes(type)
                    ? styles.alertIconError
                    : styles.alertIconWarning
            }
        />
    );

    return (
        <div className={styles.alertCellContainer}>
            {contentBefore}
            {popoverVisible ? (
                <Popover
                    title={popoverTitle}
                    content={popoverContent}
                    placement="topLeft"
                    overlayStyle={{ width: "232px" }}
                >
                    {icon}
                </Popover>
            ) : iconVisible ? (
                icon
            ) : null}
            {content}
        </div>
    );
};

AlertCellWithPopover.propTypes = {
    type: PropTypes.string,
    popoverTitle: PropTypes.string.isRequired,
    popoverContent: PropTypes.node.isRequired,
    popoverVisible: PropTypes.bool,
    content: PropTypes.node,
    contentBefore: PropTypes.node,
};
