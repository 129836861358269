import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useSetPageDescription = description => {
    const { setPageDescription } = useContext(PageLayoutContext);
    setPageDescription(description);
    useEffect(() => {
        return () => {
            setPageDescription(null);
        };
    }, [setPageDescription]);
};
