import { useEngSupportUserInfoQuery } from "@/components/CustomerPartitions/loadables";
import { Gap, H4 } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { mapLoadableItems } from "@/components/Integrations/InstancesList";
import { EntityType } from "@/components/Integrations/IntegrationsVersionHistory.overview.page";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import PartitionNameColumnRenderer from "@/components/PartitionNameColumnRenderer/PartitionNameColumnRenderer";
import { useRemovePartitionMutation } from "@/components/Partitions/loadables";
import { useRemoteLoginUsersQuery } from "@/components/RemoteLogin/loadables";
import { RequestEngSupportUserModal } from "@/components/RequestEngSupportUser/RequestEngSupportUserModal";
import { CreateSupportUserModal } from "@/components/SupportUser/CreateSupportUserModal";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useWithWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    getPartitionsListRemoteLoginTrackName,
    getPartitionsListTrackName,
} from "@/mixpanel/buttonNames";
import {
    SecurityContext,
    isAuthorizedByPermissionOr,
} from "@/security/authorization";
import {
    ACCOUNT_PARTITION_EDIT_PERMISSIONS,
    ACCOUNT_PARTITION_PERMISSIONS,
} from "@/security/permission.utils";
import { t } from "@/translations";
import { getSsoInfoAndLoginToPFX } from "@/utils/loginToPfx";
import { canBeDeletedDirectly } from "@/utils/partitionUtils";
import { isEmpty } from "lodash";
import map from "lodash/map";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { createColumns } from "./PartitionsList.columns";

const preferencesType = preferencesTypes.PARTITIONS_LIST_TABLE;

const mapRemoteLoginActionItems = (record, usersList, onRemoteLogin) => {
    return map(usersList, user => {
        return {
            title: t("partition-list.remote-login.title", { user: user }),
            onClick: () => onRemoteLogin(record, user),
            track: { name: getPartitionsListRemoteLoginTrackName(user) },
        };
    });
};

const PartitionsList = () => {
    const {
        accountAppLocations: { dashboardLocation },
        remoteLoginService,
        registryService,
        ssoService,
        mixpanelService,
        locationRouterService,
        overviewAppLocations,
    } = useDic();
    const [columns, setColumns] = useState([]); //Please do not  remove this otherwise remote login action won't show

    const supportUserInfo = useValueVisibility();
    const requestEngSupportUserModal = useValueVisibility();

    useEffect(() => {
        setColumns(
            createColumns({
                hasRights,
                hasCreatedStatus,
                dashboardLocation,
            }),
        );
    }, []);

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "nodeName,asc", filter) => {
            return registryService.getPartitions({
                queryParams: { page, size, sort, filter },
            });
        },
        [registryService],
    );

    const removePartitionMutation = useRemovePartitionMutation({
        afterSuccess: reload,
    });

    useRefreshAction(reload);

    const partitionPanel = useDetailDrawerState();

    const handlePartitionRemove = useCallback(
        record => {
            removePartitionMutation.mutate(record.serialNumber);
        },
        [removePartitionMutation],
    );

    const withWorkflow = useWithWorkflowFeedback();

    const handlePartitionDelete = useCallback(
        record => {
            withWorkflow(async () =>
                registryService.deletePartition(record.serialNumber),
            ).then(reload);
        },
        [registryService, reload, withWorkflow],
    );

    const handleRemoteLoginClick = useCallback(
        (record, user) => {
            remoteLoginService.doRemoteLogin(
                record.baseUrl,
                record.partitionName,
                user,
            );
        },
        [remoteLoginService],
    );

    const securityContext = useContext(SecurityContext);

    const authorizedForRemoteLogin = isAuthorizedByPermissionOr(
        ["loginAs_root", "loginAs_pricefx-support"],
        securityContext.permissions,
    );

    const authorizedForRemovePartitionFromList = isAuthorizedByPermissionOr(
        ["loginAs_root", "loginAs_pricefx-support"],
        securityContext.permissions,
    );

    const authorizedForDeletePartition = isAuthorizedByPermissionOr(
        ["loginAs_root"],
        securityContext.permissions,
    );

    const remoteLoginUsersResource = useRemoteLoginUsersQuery({
        hasPermission: authorizedForRemoteLogin,
    });

    const usersList = useMemo(
        () => remoteLoginUsersResource.loadable.valueMaybe() ?? [],
        [remoteLoginUsersResource.loadable],
    );

    const hasRights = useIncludesRightsForPartition(
        ACCOUNT_PARTITION_PERMISSIONS,
    );

    const hasEditRights = useIncludesRightsForPartition(
        ACCOUNT_PARTITION_EDIT_PERMISSIONS,
    );

    const hasCreatedStatus = record => record.status === "Created";

    const mapMenuItems = useCallback(
        record => maybeEngSupportUserInfo =>
            [
                {
                    title: t("general.show-detail"),
                    onClick: partitionPanel.show,
                },
                {
                    title: t("general.show-logs"),
                    onClick: () => {
                        window.open(record.logUrl, "_blank", "noopener");
                    },
                    disabled: !record?.logUrl,
                    track: {
                        name: getPartitionsListTrackName("ShowLogs"),
                    },
                },
                ...(authorizedForRemoteLogin && hasCreatedStatus(record)
                    ? mapRemoteLoginActionItems(
                          record,
                          usersList,
                          handleRemoteLoginClick,
                      )
                    : []),
                {
                    visible:
                        authorizedForRemovePartitionFromList &&
                        hasCreatedStatus(record),
                    title: t("partitions-list.modal.remove.title"),
                    confirmMessage: t("partitions-list.modal.remove.message", {
                        partitionId: record.id,
                    }),
                    onConfirm: handlePartitionRemove,
                    color: "red",
                    track: {
                        name: getPartitionsListTrackName("Remove"),
                    },
                },
                {
                    visible:
                        authorizedForDeletePartition &&
                        hasCreatedStatus(record),
                    title: t("partitions-list.modal.delete.title"),
                    confirmMessage: t(
                        canBeDeletedDirectly(record)
                            ? "partitions-list.modal.delete.message"
                            : "partitions-list.modal.request-deletion.message",
                        {
                            partitionId: record.serialNumber,
                        },
                    ),
                    onConfirm: handlePartitionDelete,
                    color: "red",
                    track: {
                        name: getPartitionsListTrackName("Delete"),
                    },
                },
                {
                    visible:
                        hasEditRights(record.customerId, record.partitionId) &&
                        hasCreatedStatus(record),
                    title: t("partitions-list.actions.create-support-user"),
                    onClick: record =>
                        supportUserInfo.show({
                            accountId: record.customerId,
                            partitionId: record.partitionId,
                        }),
                    track: {
                        name: getPartitionsListTrackName("CreateSupportUser"),
                    },
                },
                {
                    title: t(
                        "partitions-list.actions.request-eng-support-user",
                    ),
                    onClick: record => {
                        requestEngSupportUserModal.show({
                            partitionId: record.partitionId,
                        });
                    },
                    visible: (() => {
                        return (
                            hasCreatedStatus(record) &&
                            maybeEngSupportUserInfo.canCreateNew
                        );
                    })(),
                    track: {
                        name: getPartitionsListTrackName(
                            "RequestEngSupportUser",
                        ),
                    },
                },
                {
                    title: t(
                        "partitions-list.actions.remote-login-as-eng-support-user",
                    ),
                    onClick: () =>
                        getSsoInfoAndLoginToPFX({
                            pfxLoginInfo: maybeEngSupportUserInfo.pfxLoginInfo,
                            ssoService,
                            mixpanelService,
                        }),
                    visible: (() => {
                        return (
                            hasCreatedStatus(record) &&
                            maybeEngSupportUserInfo.canUse &&
                            !isEmpty(maybeEngSupportUserInfo.pfxLoginInfo)
                        );
                    })(),
                    track: {
                        name: getPartitionsListTrackName(
                            "RemoteLoginAsEngSupportUser",
                        ),
                    },
                },
                {
                    title: t("general.version-history"),
                    onClick: () => {
                        locationRouterService.navigate(
                            overviewAppLocations.partitionsVersionHistoryLocation,
                            {
                                entityId: record.partitionId,
                                entityType: EntityType.PARTITION,
                            },
                        );
                    },
                },
            ],
        [
            partitionPanel.show,
            authorizedForRemoteLogin,
            usersList,
            handleRemoteLoginClick,
            authorizedForRemovePartitionFromList,
            handlePartitionRemove,
            authorizedForDeletePartition,
            handlePartitionDelete,
            hasEditRights,
            supportUserInfo,
            requestEngSupportUserModal,
            ssoService,
            mixpanelService,
            locationRouterService,
            overviewAppLocations.partitionsVersionHistoryLocation,
        ],
    );

    const [recordToCheck, setRecordToCheck] = useState(null);
    const engSupportUserInfoResource = useEngSupportUserInfoQuery(
        recordToCheck?.partitionId,
        !isNaN(recordToCheck?.partitionId),
    );

    const actionMenu = useCallback(
        record => {
            const props = {
                items: mapLoadableItems(
                    mapMenuItems(record),
                    engSupportUserInfoResource,
                ),
                wrapperProps: {
                    onClick: () => {
                        setRecordToCheck(record);
                    },
                },
            };

            return <ActionButton record={record} {...props} />;
        },
        [mapMenuItems, engSupportUserInfoResource],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                defaultSort={{
                    fieldName: "projectName",
                    sortDir: "ascending",
                }}
                fixed
                rowKey="partitionId"
                datasetSlicing="server"
                preferencesType={preferencesType}
                hasColumnAutofit
                hasColumnResizing
                exportUrl="/api/registry/partition-info/export"
                panelControl={partitionPanel}
                renderPanelTitle={record => record?.serialNumber}
                renderPanelHeader={record => (
                    <>
                        <H4>{record.serialNumber}</H4>
                        <PartitionNameColumnRenderer
                            partitionId={record.partitionId}
                            hasRight={
                                hasCreatedStatus(record) &&
                                hasRights(record.customerId, record.partitionId)
                            }
                            projectId={record.customerId}
                        />
                        <Gap />
                    </>
                )}
                {...tableProps}
            />
            <CreateSupportUserModal
                visible={supportUserInfo.visible}
                accountId={supportUserInfo.value?.accountId}
                partitionId={supportUserInfo.value?.partitionId}
                onClose={supportUserInfo.hide}
            />
            <RequestEngSupportUserModal
                visible={requestEngSupportUserModal.visible}
                partitionId={requestEngSupportUserModal.value?.partitionId}
                onClose={requestEngSupportUserModal.hide}
            />
        </>
    );
};

export default PartitionsList;
