import { EntityType } from "@/constants/CommonConstats";
import { ISV_CONNECTIONS_LOGS } from "@/constants/sessionState.constants";
import { getData } from "@/services/utils";
import React from "react";
import { useDic } from "../Dic/useDic.hook";
import { ElasticSearchPanelWithActions } from "../Integrations/Dashboard/Tabs/ElasticSearchPanel";

export const ISVLogHistory = ({ visible, accountId, isvConnectionId }) => {
    const { axiosService } = useDic();
    const fetchData = params =>
        axiosService
            .post(`/api/isv-connections/${isvConnectionId}/logs`, params)
            .then(getData);
    const exportLogs = params =>
        axiosService.post(
            `/api/isv-connections/${isvConnectionId}/logs/export`,
            params,
        );

    return (
        <ElasticSearchPanelWithActions
            fetchData={fetchData}
            // defaultColumns={DEFAULT_COLUMNS}
            exportLogMessages={exportLogs}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={ISV_CONNECTIONS_LOGS}
            // initialQuery={initialQuery}
            visible={visible}
        />
    );
};
