import {
    Alert,
    Button,
    ButtonMenu,
    Divider,
    Forms,
    Gap,
    H4,
    message,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import {
    isAuthorizedByPermission,
    SecurityContext,
} from "@/security/authorization";
import { DEFAULT_WORKFLOW_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Minus } from "@pricefx/unity-components/src/icons/unicons/minus.svg";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { cloneDeep, entries, isEmpty, merge, range, set, size } from "lodash";
import flatMap from "lodash/fp/flatMap";
import mergeAll from "lodash/fp/mergeAll";
import pipe from "lodash/fp/pipe";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    createBasePath,
    DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL,
    PERMISSION_ASSIGNMENT_APPROVAL,
    WF_PERMISSION_CONDITIONS,
    WF_PERMISSION_CONDITIONS_DEFAULT_WF,
    WF_TYPES,
} from "../Workflow.utils";
import { CreateWorkflowApprovalFormComponent } from "./CreateWorkflowApprovalForm.component";

const { useForm, Form, Field, Fields, pmValidators } = Forms;

export const filterGlobalAccountValue = values => {
    const { projectId, ...restValues } = values;
    return projectId === GLOBAL_ACCOUNT_ID ? restValues : values;
};

const preselectGlobalAccount = initialValues => {
    return initialValues.projectId === null
        ? { ...initialValues, projectId: GLOBAL_ACCOUNT_ID }
        : initialValues;
};

const GLOBAL_ACCOUNT_ID = -1;

const prepareInitValues = data => {
    if (!data) {
        return [{}, null];
    }

    const copy = cloneDeep(data);
    const result = {};
    const initApprovals = [];
    copy?.context?.steps?.forEach((value, index) => {
        const trueKey = createBasePath(index);
        initApprovals.push(trueKey);
        entries(value).forEach(
            ([innerKey, innerValue]) =>
                (result[trueKey + "-" + innerKey] = innerValue),
        );
    });
    delete copy.context;
    entries(copy).forEach(([key, value]) => (result[key] = value));

    return [preselectGlobalAccount(result), initApprovals];
};

const resetApproves = pipe(
    flatMap(approveId => {
        const basePath = createBasePath(approveId);
        return {
            [`${basePath}-persons`]: undefined,
            [`${basePath}-groups`]: undefined,
            [`${basePath}-adminGroups`]: undefined,
        };
    }),
    mergeAll,
);

const getAvailableTypes = isGlobalWf => types => {
    if (isGlobalWf) {
        return types;
    }
    return types.filter(type => type.isGlobalWorkflowOnly === false);
};

const mapTypesToOptions = types =>
    types.map(({ label, value }) => ({ label, value }));

const remapToDotNotation = path => path.replaceAll("-", ".");

export const CreateWorkflow = ({
    accountsLoadable,
    initialValues: passedInitialValues,
    usedTypesAndConditions,
    onSave,
    onCancel,
}) => {
    const [initialValues, initApprovals] =
        prepareInitValues(passedInitialValues);

    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const roles = wfType => {
        if (wfType === DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL) {
            return [
                { label: "Account Owner", value: "ACCOUNT_OWNER" },
                {
                    label: "Integration Manager Instance Owner",
                    value: "IM_INSTANCE_OWNER",
                },
            ];
        } else {
            return [{ label: "Account Owner", value: "ACCOUNT_OWNER" }];
        }
    };

    const isEditForm = !isEmpty(initialValues);

    const { formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit: ({ values: { id, ...values } }) => {
            const asObject = {};
            entries(filterGlobalAccountValue(values)).forEach(([key, value]) =>
                set(asObject, remapToDotNotation(key), value),
            );
            onSave({ id, values: asObject });
        },
    });

    const { adminApiService, groupService, userService } = useDic();
    const confirmModal = useConfirmModal();

    const securityContext = useContext(SecurityContext);

    const hasRightsToGlobalAccount = isAuthorizedByPermission(
        DEFAULT_WORKFLOW_EDIT_PERMISSION,
        securityContext.permissions,
    );

    const [approvals, setApprovals] = useState(size(initApprovals) || 1);
    const [typeWarning, setTypeWarning] = useState(false);
    const [workflowType, setWorkflowType] = useState(initialValues?.wfType);
    const [accountId, setAccountId] = useState(initialValues?.projectId);
    const isGlobalWorkflow = accountId === GLOBAL_ACCOUNT_ID;

    useEffect(() => {
        if (isGlobalWorkflow) {
            userService.getFilteredUsers("a").then(({ data }) =>
                setUsers(
                    data.map(({ username }) => ({
                        label: username,
                        value: username,
                    })),
                ),
            );

            groupService
                .getGroupsList()
                .then(({ data }) =>
                    setGroups(
                        data.map(({ name }) => ({ label: name, value: name })),
                    ),
                )
                .catch(() => {
                    message.error("Unable to fetch project's group");
                });
        } else if (accountId !== undefined) {
            adminApiService
                .fetchProjectUsers(accountId)
                .then(({ data }) =>
                    setUsers(
                        data.map(({ username }) => ({
                            label: username,
                            value: username,
                        })),
                    ),
                )
                .catch(() => {
                    message.error("Unable to fetch project's users");
                });
            adminApiService
                .fetchProjectGroups(accountId)
                .then(({ data }) =>
                    setGroups(
                        data.map(({ name, id }) => ({
                            label: name,
                            value: id,
                        })),
                    ),
                )
                .catch(() => {
                    message.error("Unable to fetch project's group");
                });
        }
    }, [isGlobalWorkflow, accountId]);

    const addApprovalStep = useCallback(() => {
        setApprovals(approvals + 1);
    }, [approvals, setApprovals]);

    const removeApprovalStep = useCallback(() => {
        setApprovals(approvals - 1);
    }, [approvals, setApprovals]);

    const onTypeChange = useCallback(
        async ({ value: workflowType }) => {
            setTypeWarning(usedTypesAndConditions.includes(workflowType));
            setWorkflowType(workflowType);
        },

        [setWorkflowType, setTypeWarning, usedTypesAndConditions],
    );

    const onAccountChange = useCallback(
        async ({ value: newAccountId }) => {
            if (accountId !== undefined && accountId !== newAccountId) {
                confirmModal.confirm({
                    title: t("general.confirm"),
                    message: t("workflow-create.account-change-info"),
                    okText: t("general.confirm"),
                    cancelText: t("general.cancel"),
                    icon: <QuestionCircleOutlined />,
                    onConfirm: () => {
                        setAccountId(newAccountId);
                        setWorkflowType(undefined);
                        setValues(
                            merge(
                                { projectId: newAccountId, wfType: undefined },
                                resetApproves(range(approvals)),
                            ),
                        );
                    },
                    onCancel: () => {
                        setAccountId(accountId);
                        setValues({
                            projectId: accountId,
                        });
                    },
                });
            } else {
                setAccountId(newAccountId);
                setValues({ projectId: newAccountId });
            }
        },

        [accountId],
    );

    const onConditionChange = useCallback(
        async ({ value: workflowCondition }) => {
            setTypeWarning(
                usedTypesAndConditions.includes(
                    workflowType + workflowCondition,
                ),
            );
        },

        [workflowType, setTypeWarning, usedTypesAndConditions],
    );

    const addGlobalAccountOption = accounts => {
        return hasRightsToGlobalAccount
            ? [
                  {
                      label: t("workflow-create.all-accounts"),
                      value: GLOBAL_ACCOUNT_ID,
                  },
                  ...accounts,
              ]
            : accounts;
    };

    const availableAccounts = useMemo(() => {
        return addGlobalAccountOption(
            accountsLoadable.state === "hasValue"
                ? accountsLoadable.contents.map(account => ({
                      label: account.name,
                      value: account.id,
                  }))
                : [],
        );
    }, [accountsLoadable.state]);

    return (
        <Modal visible onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={
                        !isEditForm
                            ? t("workflow-create.title.create")
                            : t("workflow-create.title.edit")
                    }
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form
                        onSubmit={handleSubmit}
                        formId={formId}
                        layout="vertical"
                    >
                        <Field
                            as="div"
                            name="id"
                            initialValue={initialValues?.id}
                        />

                        <Fields.Input
                            name="name"
                            initialValue={initialValues?.name}
                            inputWidth="max"
                            label={t("workflow-create.label.name")}
                            placeholder={t("workflow-create.placeholder.name")}
                            required
                            validator={pmValidators.isRequired}
                            data-test="create-workflow-name"
                        />

                        <Fields.Select
                            name="projectId"
                            initialValue={initialValues?.projectId}
                            inputWidth="max"
                            label={t("workflow-create.label.account")}
                            placeholder={t(
                                "workflow-create.placeholder.account",
                            )}
                            loading={accountsLoadable.state === "loading"}
                            options={availableAccounts}
                            onChange={onAccountChange}
                            disabled={isEditForm}
                            required
                            allowClear={false}
                            validator={pmValidators.isRequired}
                            dataTest="create-workflow-projectId"
                        />

                        <Fields.Select
                            name="wfType"
                            initialValue={initialValues?.wfType}
                            inputWidth="max"
                            label={t("workflow-create.label.action")}
                            placeholder={t(
                                "workflow-create.placeholder.action",
                            )}
                            options={pipe(
                                getAvailableTypes(isGlobalWorkflow),
                                mapTypesToOptions,
                            )(WF_TYPES)}
                            onChange={onTypeChange}
                            disabled={isEditForm}
                            required
                            validator={pmValidators.isRequired}
                        />

                        {workflowType === PERMISSION_ASSIGNMENT_APPROVAL && (
                            <Fields.Select
                                name="wfCondition"
                                initialValue={initialValues?.wfCondition}
                                inputWidth="max"
                                label={t(
                                    "workflow-create.label.PERMISSION_ASSIGNMENT_WORKFLOW",
                                )}
                                placeholder={t(
                                    "workflow-create.placeholder.PERMISSION_ASSIGNMENT_WORKFLOW",
                                )}
                                onChange={onConditionChange}
                                options={[
                                    ...WF_PERMISSION_CONDITIONS,
                                    ...(isGlobalWorkflow
                                        ? WF_PERMISSION_CONDITIONS_DEFAULT_WF
                                        : []),
                                ]}
                                required
                                disabled={isEditForm}
                                validator={pmValidators.isRequired}
                            />
                        )}

                        {typeWarning && (
                            <Alert
                                message={t("workflow-create.warning.used-type")}
                                type="warning"
                            />
                        )}

                        <H4>{t("workflow-create.text.approval")}</H4>
                        <Text>{t("workflow-create.required.approval")}</Text>
                        <Gap size="small" />
                        {range(approvals).map(index => (
                            <div key={`approval-${index}`}>
                                {index > 0 ? (
                                    <Divider>
                                        {t("workflow-create.text.and")}
                                    </Divider>
                                ) : null}

                                <CreateWorkflowApprovalFormComponent
                                    formId={formId}
                                    accountId={accountId}
                                    isGlobalWorkflow={isGlobalWorkflow}
                                    id={createBasePath(index)}
                                    users={users}
                                    groups={groups}
                                    roles={roles(workflowType)}
                                    initialValues={initialValues}
                                    setTouched={setTouched}
                                />
                            </div>
                        ))}
                        <Button
                            size="small"
                            onClick={addApprovalStep}
                            icon={Plus}
                            label={t("workflow-create.button.add-approval")}
                        />
                        {approvals > 1 ? (
                            <Button
                                size="small"
                                onClick={removeApprovalStep}
                                icon={Minus}
                                label={t(
                                    "workflow-create.button.remove-approval",
                                )}
                            />
                        ) : (
                            <></>
                        )}
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    type: "primary",
                                    label: !isEditForm
                                        ? t("general.create")
                                        : t("general.save"),
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CreateWorkflow.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    usedTypesAndConditions: PropTypes.arrayOf(PropTypes.string).isRequired,
    accountsLoadable: PropTypes.object.isRequired,
};
