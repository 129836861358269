import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    hasError,
    isLoading,
    useMutationLoadableWithNotification,
} from "@/modules/loadable";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { useHasGlobalPermission } from "@/security/hooks/useHasGlobalPermission.hook";
import {
    GROUPS_MANAGEMENT_PERMISSIONS,
    GROUP_MANAGEMENT_EDIT_PERMISSION,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { AdminGroupRightsDrawer } from "../../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/AdminGroupRightsDrawer";
import { groupService } from "../../../apps/administrationApp/services/group.service";
import { GroupGlobalPermissionsDrawerContainer } from "../Users/GlobalPermissions/GroupGlobalPermissionsDrawerContainer";
import { EditGroupModal } from "./EditGroupModal";
import { NewGroupModal } from "./NewGroupModal";
import { useDeleteGroupResource } from "./loadables";

const preferencesType = preferencesTypes.ADMIN_GROUPS_LIST_TABLE;

const VIEWS = {
    DEFAULT: "default",
    GLOBAL_PERMISSIONS: "global_permissions",
    PERMISSIONS: "permissions",
    NEW_GROUP: "new_group",
    EDIT_GROUP: "edit_group",
};
export const GroupListContainer = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const [tableProps, { reload: reloadGroups }] = useFetchPage(
        (page, size, sort, filter) =>
            groupService.fetchGroups(page, size, sort, filter),
        [],
    );
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [view, setView] = useState(VIEWS.DEFAULT);
    const multipleIdsPermissions = useCallback(groupNames => {
        setSelectedGroups(groupNames);
        setView(VIEWS.PERMISSIONS);
    }, []);
    const singleRecordPermissions = useCallback(
        group => {
            multipleIdsPermissions([group.name]);
        },
        [multipleIdsPermissions],
    );

    const { axiosService } = useDic();

    const syncADManagedGroupsMutationResource =
        useMutationLoadableWithNotification(
            () => axiosService.post("/api/admin/groups/ad-managed/sync"),
            [axiosService],
            "group-list.notification.sync-with-ad.success",
            "group-list.notification.sync-with-ad.error",
        );

    useBreadcrumbButtons(
        () => [
            {
                type: "primary",
                label: "group-list.button.create-group",
                onClick: () => {
                    setView(VIEWS.NEW_GROUP);
                },
                permissions: [GROUP_MANAGEMENT_EDIT_PERMISSION],
            },
            {
                type: "secondary",
                label: "group-list.button.sync-with-ad",
                loading: isLoading(syncADManagedGroupsMutationResource),
                disabled: hasError(syncADManagedGroupsMutationResource),
                onClick: () => syncADManagedGroupsMutationResource.mutate(),
                permissions: [GROUP_MANAGEMENT_EDIT_PERMISSION],
            },
        ],
        [syncADManagedGroupsMutationResource],
    );

    const deleteGroupResource = useDeleteGroupResource({
        afterSuccess: reloadGroups,
    });

    const showGroupGlobalPermissions = useCallback(record => {
        setSelectedGroups([record.name]);
        setView(VIEWS.GLOBAL_PERMISSIONS);
    }, []);

    const onClose = useCallback(() => {
        setView(VIEWS.DEFAULT);
        // setSelectedGroups([]); // app crash https://pricefx.atlassian.net/browse/PFIM-6557
    }, []);

    const isPowerUser = useHasGlobalPermission("permission.power.user");
    const selectionContextMenu = useMemo(
        () => [
            {
                label: t("general.mass-permissions"),
                onClick: () => multipleIdsPermissions(selectedGroups),
            },
        ],
        [selectedGroups, multipleIdsPermissions],
    );
    const rowSelection = useSyncRowSelection(selectedGroups, setSelectedGroups);
    const onEdit = useCallback(group => {
        setSelectedGroups([group.name]);
        setView(VIEWS.EDIT_GROUP);
    }, []);

    const actionMenu = useCallback(
        record => (
            <VisibleWhenHasPermission
                permission={GROUP_MANAGEMENT_EDIT_PERMISSION}
            >
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            onClick: onEdit,
                        },
                        {
                            title: t("general.permissions"),
                            onClick: singleRecordPermissions,
                            permission: ["permission.power.user"],
                        },
                        {
                            title: t("general.global-permissions"),
                            onClick: showGroupGlobalPermissions,
                            permission: [GROUP_MANAGEMENT_EDIT_PERMISSION],
                        },
                        {
                            title: t("group-list.title.delete-group"),
                            confirmMessage: t(
                                "group-list.message.delete-group",
                                {
                                    groupName: record.name,
                                },
                            ),
                            onConfirm: deleteGroupResource.mutate,
                            color: "red",
                        },
                    ]}
                />
            </VisibleWhenHasPermission>
        ),
        [
            deleteGroupResource.mutate,
            singleRecordPermissions,
            showGroupGlobalPermissions,
            onEdit,
        ],
    );

    return (
        <VisibleWhenHasPermission permission={GROUPS_MANAGEMENT_PERMISSIONS}>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                fixed
                rowKey="name"
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl="/api/admin/groups/table/export"
                columns={[
                    {
                        type: fieldTypes.TEXT,
                        label: t("group-list.header.name"),
                        name: "name",
                        filterable: "string",
                        className: "pmTable__column--withActionButton",
                    },
                    {
                        type: fieldTypes.TEXT,
                        label: t("group-list.header.label"),
                        name: "label",
                        filterable: "string",
                    },
                    {
                        type: fieldTypes.BOOLEAN,
                        label: t("group-list.header.adManaged"),
                        name: "adManaged",
                    },
                ]}
                rowSelection={!isPowerUser ? undefined : rowSelection}
                selectionContextMenu={
                    !isPowerUser ? undefined : selectionContextMenu
                }
                {...tableProps}
            />
            <GroupGlobalPermissionsDrawerContainer
                groupId={selectedGroups[0]}
                visible={
                    !!selectedGroups[0] && view === VIEWS.GLOBAL_PERMISSIONS
                }
                onCancel={onClose}
                onSaved={onClose}
            />
            <AdminGroupRightsDrawer
                groupNames={selectedGroups}
                visible={!!selectedGroups.length && view === VIEWS.PERMISSIONS}
                onClose={onClose}
            />
            <NewGroupModal
                visible={view === VIEWS.NEW_GROUP}
                onClose={onClose}
                reloadGroups={reloadGroups}
            />
            <EditGroupModal
                visible={view === VIEWS.EDIT_GROUP}
                onClose={onClose}
                groupName={selectedGroups?.[0]}
                reloadGroups={reloadGroups}
            />
        </VisibleWhenHasPermission>
    );
};

GroupListContainer.propTypes = {
    setButtonData: PropTypes.func,
    cleanButtonData: PropTypes.func,
};
