import {
    ButtonMenu,
    Tabs,
    UnityLayout,
    UnitySteps,
} from "@/components/DesignSystem";
import { BreadcrumbContext } from "@/components/PageLayout/BreadcrumbContext";
import { useHeaderSteps } from "@/components/PageLayout/useHeaderSteps.hook";
import { useHeaderAlertProp } from "@/components/PageLayout/useSetAlert.hook";
import { logger } from "@/modules/logger";
import React, { useContext } from "react";
import { PageLayoutContext } from "../PageLayoutContext";

export const Header = ({ ...props }) => {
    const {
        title,
        description,
        actionButtons,
        backButtonAction,
        hideBackButton,
        headerTabs,
        hideActionButtons,
    } = useContext(PageLayoutContext);
    const { breadcrumbs } = useContext(BreadcrumbContext);
    const alerts = useHeaderAlertProp();
    const { headerSteps } = useHeaderSteps();

    return (
        <UnityLayout.Header
            title={title}
            description={description}
            {...(backButtonAction
                ? { onClickBack: backButtonAction }
                : { hideBackButton })}
            breadcrumbs={breadcrumbs}
            actionButtons={
                hideActionButtons ? undefined : (
                    <ButtonMenu buttons={actionButtons} />
                )
            }
            alert={alerts}
            steps={
                headerSteps.steps?.length > 0 ? (
                    <UnitySteps {...headerSteps} />
                ) : null
            }
            tabs={
                headerTabs && (
                    <Tabs {...headerTabs.tabsProps}>
                        {headerTabs.tabPanes.map(({ key, ...props }) => (
                            <Tabs.TabPane key={key} {...props} />
                        ))}
                    </Tabs>
                )
            }
            {...props}
        />
    );
};
