import { useDic } from "@/components/Dic/useDic.hook";
import { EventWFForm } from "@/components/EventWorkflows/EventWFForm";
import {
    useEventWFQuery,
    useWorkflowApprovalRunning,
} from "@/components/EventWorkflows/loadables";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const ShowEventWorkflowPage = () => {
    const { accountId, wfId } = useAccountAppParams();
    const title = t(
        "location.accountApp.eventOrchestration.eventWorkflows.view",
    );
    useSetPageTitle(title);
    const {
        locationRouterService,
        accountAppLocations: { eventWorkflowsLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(eventWorkflowsLocation),
        [eventWorkflowsLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    const eventWFQuery = useEventWFQuery({ accountId, wfId });

    return (
        <LoadableRenderer
            loadable={eventWFQuery.loadable}
            hasValue={initialValues => (
                <EventWFForm
                    accountId={accountId}
                    title={title}
                    initialValues={initialValues}
                    onCancel={onCancel}
                    readOnly={true}
                />
            )}
        />
    );
};
