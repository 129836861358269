import {
    neededSfxParameters,
    normalizeSftpSfxParameters,
} from "@/components/ISVConnections/steps/General.step";
import { isLoading } from "@/modules/loadable";
import { isEmpty, isEqual, pick } from "lodash/fp";
import React from "react";

export const neededConnectionParameters = [
    "channelId",
    "baseUrl",
    "sftpUrl",
    "sftpPort",
    "sftpUsername",
];

export const useCompareActualAndPartitionParameters = ({
    initialValues,
    partitionAssetsLoadable,
    onDifferent,
}) => {
    const initialValuesRef = React.useRef(initialValues);
    const previousValuesRef = React.useRef({});
    const currentValuesRef = React.useRef({});
    const parametersAreDifferentRef = React.useRef(false);

    if (!isEqual(initialValues, initialValuesRef.current)) {
        initialValuesRef.current = initialValues;
        currentValuesRef.current = {};
        previousValuesRef.current = {};
        parametersAreDifferentRef.current = false;
    }

    if (
        !isLoading(partitionAssetsLoadable) &&
        isEmpty(currentValuesRef.current)
    ) {
        const sfxParameters = normalizeSftpSfxParameters(
            pick(
                neededSfxParameters,
                partitionAssetsLoadable.valueMaybe()?.sfxSyncData,
            ),
        );
        const currentParameters = pick(
            neededConnectionParameters,
            initialValues,
        );

        neededConnectionParameters.forEach(param => {
            if (sfxParameters[param] !== currentParameters[param]) {
                parametersAreDifferentRef.current = true;
                previousValuesRef.current[param] = currentParameters[param];
            }
            currentValuesRef.current[param] = sfxParameters[param] ?? "";
        });

        onDifferent(Object.keys(previousValuesRef.current));
    }

    return {
        areDifferent: parametersAreDifferentRef.current,
        currentValues: currentValuesRef.current,
        previousValues: previousValuesRef.current,
    };
};
