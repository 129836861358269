import { showMassDelete } from "@/components/DataUploads/utils";
import { BusinessKeyLengthTable } from "@/components/DataUploads/Wizard/BusinessKeyLengthTable";
import { sumValues } from "@/components/DataUploads/Wizard/hooks/useBusinessKeys.hook";
import { Button, Gap, H3 } from "@/components/DesignSystem";
import Paragraph from "@/components/DesignSystem/Paragraph/Paragraph";
import { useDic } from "@/components/Dic/useDic.hook";
import { ResultPreview } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/ResultPreview.component";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { massDeleteOptions } from "@/constants/deployment.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { HasValueLoadable } from "@/modules/loadable/useQueryLoadable.hook";
import { getData } from "@/services/utils";
import { t, T } from "@/translations";
import { isEmpty, noop } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

const usePartition = ({ projectId, partitionId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/projects/${projectId}/partition-assets/${partitionId}`,
                )
                .then(getData),
        [projectId, axiosService, partitionId],
    );
};

export const UploadReview = ({
    allValues = {},
    projectId,
    partitionId,
    tableExampleData = {},
    onSubmit,
    isSubmitting,
    onCancel,
    onBack,
}) => {
    const partitionName = usePartition({
        projectId,
        partitionId,
    }).loadable.valueMaybe()?.name;

    return (
        <>
            <H3 data-test="upload-review-header">
                {t("data-upload.step-name.upload-review")}
            </H3>
            <span data-test="upload-review-info-0">
                <T
                    id={"package-data-upload.table-header.review.perex"}
                    values={{
                        fileName: tableExampleData.fileName,
                        partitionName,
                    }}
                />
            </span>
            <Gap size="small" />
            <ul>
                {showMassDelete(allValues.entityType) && (
                    <li data-test="upload-review-info-1">
                        {
                            massDeleteOptions.find(
                                ({ value }) => value === allValues.massDelete,
                            ).label
                        }
                    </li>
                )}
                <li data-test="upload-review-info-2">
                    {t("data-upload.review.starts-at", {
                        date: allValues.schedule?.format() || "now",
                    })}
                </li>
                <li data-test="upload-review-info-3">
                    {allValues.convertEmptyStringToNull
                        ? t("data-upload.review.convert-empty-null")
                        : t("data-upload.review.convert-empty-string")}
                </li>
                {!!allValues.sendEmail && (
                    <li>{t("data-upload.review.email")}</li>
                )}
            </ul>
            <Paragraph data-test="upload-review-info-4" size={2}>
                {t("data-upload.review.rows-note")}
            </Paragraph>
            <ResultPreview
                partitionId={partitionId}
                allValues={allValues}
                tableExampleData={tableExampleData}
                onBack={onBack}
            />
            <Gap />
            <Button
                type="primary"
                disabled={isSubmitting}
                onClick={onSubmit}
                label={t("partition-send-data.button.finish")}
                data-test="submit"
            />
            <BackButton onBack={onBack} />
            <Button
                type="text"
                onClick={onCancel}
                label={<T id="partition-send-data.button.cancel" />}
                data-test="cancel"
            />

            <Gap size="small" />
            {!isEmpty(allValues.businessKey) && (
                <>
                    <BusinessKeyLengthTable
                        disabled
                        expanded
                        formProps={{ formId: "-", handleSubmit: noop }}
                        loadableBusinessKeys={HasValueLoadable(
                            allValues.businessKey,
                        )}
                        keysLength={sumValues(allValues.businessKey)}
                    />
                    <Gap />
                </>
            )}
        </>
    );
};

UploadReview.propTypes = {
    allValues: PropTypes.object.isRequired,
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    tableExampleData: PropTypes.object,
    onBack: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
};
