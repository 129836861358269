import { useBusinessKeys } from "@/components/DataUploads/Wizard/hooks/useBusinessKeys.hook";
import { Forms, Gap, H3 } from "@/components/DesignSystem";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { VENDOR } from "@/components/Mappers/MapperTableWithCustomization/constants";
import { useMapperStateWithInitialization } from "@/components/Mappers/MapperTableWithCustomization/hooks/useMapperStateWithInitialization.hook";
import { useOutputOptions } from "@/components/Mappers/MapperTableWithCustomization/loadableResources";
import {
    MapperTableWithCustomization,
    useInputOptionsWithTypes,
} from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import {
    loadableFromMaybeValue,
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React, { useMemo } from "react";
const {
    Fields,

    pmValidators,
} = Forms;

const FieldsStep = ({
    stepFormProps: { allValues, setAllValues },
    form: { formId, setValues, getBag, setTouched },
    allStepsProps,
    stepProps: { setIsValidMapper },
}) => {
    const {
        pfxEntityType: entityType,
        pfxEntityName: entityName,
        type: direction,
        fileInfo,
    } = allValues;
    const { isvConnection } = allStepsProps;
    const { partitionId, vendor } = isvConnection;

    const pricefxOptionsResource = useOutputOptions({
        sourceType: "partitions",
        sourceId: partitionId,
        entityType,
        entityName,
        objectTypeErrors: undefined, // Validate previously selected combination?
    });
    const { inputOptionsWithTypes, tableExampleDataMap } =
        useInputOptionsWithTypes({ tableExampleData: fileInfo });

    const [leftSide, rightSide] = useMemo(() => {
        if (direction === MAPPER_DIRECTION.UPLOAD) {
            return [
                {
                    vendor,
                    optionsLoadable: loadableFromMaybeValue(
                        inputOptionsWithTypes,
                    ),
                },
                {
                    vendor: VENDOR.PRICEFX,
                    optionsLoadable: pricefxOptionsResource.loadable,
                },
            ];
        } else {
            return [
                {
                    vendor: VENDOR.PRICEFX,
                    optionsLoadable: pricefxOptionsResource.loadable,
                },
                {
                    vendor,
                    optionsLoadable: loadableFromMaybeValue(
                        inputOptionsWithTypes,
                    ),
                },
            ];
        }
    }, [
        direction,
        inputOptionsWithTypes,
        pricefxOptionsResource.loadable,
        vendor,
    ]);

    const businessKeys = useBusinessKeys({
        entityName,
        entityType,
        partitionId,
    });

    const initMapper = useMemo(() => allValues.mapper, []);

    const vendors = [leftSide.vendor, rightSide.vendor];

    const { setMapper, errors, loadableMapper } =
        useMapperStateWithInitialization({
            vendors,
            direction,
            entityType,
            entityName,
            businessKey: businessKeys.formValues,
            inputOptionsLoadable: leftSide.optionsLoadable,
            outputOptionsLoadable: rightSide.optionsLoadable,
            initMapper, // TODO
            tableData: fileInfo,
        });

    const loadables = useComposeLoadablesMemoized([
        leftSide.optionsLoadable,
        rightSide.optionsLoadable,
        loadableMapper,
    ]);

    useLoadableHasValueChangedEffect(loadableMapper, mapperRows => {
        setIsValidMapper(
            errors.general.length === 0 && errors.inputs.length === 0,
        );
        setAllValues(allValues => ({ ...allValues, mapper: mapperRows }));
    });
    const vendorEntityType = useFieldValue({
        formId,
        name: "vendorEntityType",
    });
    const isTransactionDateColumnRequired = useMemo(
        () =>
            direction === MAPPER_DIRECTION.DOWNLOAD &&
            vendor === VENDOR.ENABLE &&
            vendorEntityType === "TRANSACTIONS",
        [direction, vendor, vendorEntityType],
    );

    return (
        <LoadableRenderer
            loadable={loadables}
            loading={() => <LoadableRenderer.Spinner />}
            hasValue={([leftSideOptions, rightSideOptions, mapper]) => {
                return (
                    <>
                        {isTransactionDateColumnRequired ? (
                            <Fields.Select
                                required
                                name="transactionDateColumn"
                                tooltip={t(
                                    "isv-connections.data-mapping.steps.fields.transactionDateColumn.tooltip",
                                )}
                                label={t(
                                    "isv-connections.data-mapping.steps.fields.transactionDateColumn",
                                )}
                                options={fileInfo.columns.map(column => ({
                                    label: column.name,
                                    value: column.name,
                                }))}
                                showSearch
                                validator={pmValidators.isRequired}
                            />
                        ) : null}
                        <Gap size="large" />
                        <H3>
                            {t(
                                "isv-connections.data-mapping.steps.fields-mapping",
                            )}
                        </H3>
                        <MapperTableWithCustomization
                            vendors={vendors}
                            partitionId={partitionId}
                            mapper={mapper}
                            errors={errors}
                            apiErrors={[]} // TODO
                            onChange={setMapper}
                            inputOptionsWithTypes={leftSideOptions}
                            outputOptions={rightSideOptions}
                            readOnly={false}
                            tableExampleData={fileInfo}
                            tableExampleDataMap={tableExampleDataMap}
                            withConverterWarning={false}
                            parserConfig={allValues}
                            entityName={entityName}
                            entityType={entityType}
                            setValues={setValues}
                            setTouched={setTouched}
                            getBag={getBag}
                            onColumnTypeChange={(name, type) => {
                                // TODO
                                // onFileInfo(fileInfo =>
                                //     !fileInfo
                                //         ? fileInfo
                                //         : {
                                //               ...fileInfo,
                                //               columns: fileInfo.columns.map(c =>
                                //                   c?.name !== name ? c : { ...c, type },
                                //               ),
                                //           },
                                // );
                            }}
                        />
                    </>
                );
            }}
        />
    );
};

export default FieldsStep;
