import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useRefreshAction = (fn, canSet = true) => {
    const { setRefreshAction } = useContext(PageLayoutContext);

    useEffect(() => {
        if (canSet) {
            setRefreshAction(() => fn);
        }
        return () => {
            if (canSet) {
                setRefreshAction(null);
            }
        };
    }, [setRefreshAction, fn, canSet]);
};
