import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useEnableDeeplinksQuery = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService.get(`/api/admin/enable-deep-links`).then(getData),
        [axiosService],
    );
};

export const useEnableDeeplinksMutation = ({ afterSuccess }) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async data => {
            await axiosService.post(`/api/admin/enable-deep-links`, data);
            currentAfterSuccess();
        },
        [axiosService, currentAfterSuccess],
    );
};
