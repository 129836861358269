import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { STATUSES } from "@/modules/loadable";
import { t } from "@/translations";
import { useAccounts } from "@/utils/loadables/accounts.loadables";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useCreateJiraTaskResource } from "./loadables";

const notBlankValidator = Forms.pmValidators.notBlank();

const CP_DEFAULT = null;
const CUSTOMER_PRIORITY_OPTIONS = [
    { value: CP_DEFAULT, label: "None" },
    { value: 11902, label: "Nice to have" },
    { value: 11901, label: "Low" },
    { value: 11900, label: "Medium" },
    { value: 11899, label: "High" },
    { value: 11898, label: "Critical" },
    { value: 11897, label: "Blocker" },
];

const COMPONENTS_OPTIONS = [
    { value: 11642, label: "Unity" },
    { value: 11706, label: "PlatformManager" },
];

// TBD @Jakub: Some fields not available for now, some options hardcoded for now
// TBD @Peter: Loadable select options UX unification
export const CreateProductRequestModal = ({ visible, onClose, afterSave }) => {
    const createJiraTaskResource = useCreateJiraTaskResource({ afterSave });

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onCancel: onClose,
        onSubmit: ({ values }) => createJiraTaskResource.mutate(values),
    });
    // const canFetch = visible;
    // const componentsOptionsResource = useQueryLoadable(
    //     async () => (canFetch ? mockFetchOptions() : pendingPromise()),
    //     [canFetch]
    // );
    // const customersOptionsResource = useQueryLoadable(
    //     async () => (canFetch ? mockFetchOptions() : pendingPromise()),
    //     [canFetch]
    // );

    const accounts = useAccounts();

    const accountsOptions =
        accounts.loadable.state === STATUSES.hasValue
            ? accounts.loadable.contents.map(account => ({
                  label: account.name,
                  value: account.name,
                  key: account.id,
              }))
            : [];

    const hasOnlyOneAccount = accountsOptions.length === 1;

    useSetValidatedInitialValues(
        {
            initialValues: {
                "customers[]": hasOnlyOneAccount
                    ? [accountsOptions[0]?.value]
                    : undefined,
            },
            setValues,
            setTouched,
        },
        [accountsOptions, hasOnlyOneAccount],
    );
    const consentValidator = useCallback(
        value =>
            !value
                ? Forms.error(t("general.validation.required"))
                : Forms.success(),
        [],
    );

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("product-enhancement.request-modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                required
                                name="summary"
                                data-test="summary-input"
                                label={t(
                                    "product-enhancement.request-form.summary.label",
                                )}
                                placeholder={t(
                                    "product-enhancement.request-form.summary.placeholder",
                                )}
                                tooltip={t(
                                    "product-enhancement.request-form.summary.tooltip",
                                )}
                                validator={notBlankValidator}
                                allowClear={false}
                            />
                            <Forms.Fields.TextArea
                                required
                                name="problemDescription"
                                data-test="problem-description-input"
                                label={t(
                                    "product-enhancement.request-form.problem-description.label",
                                )}
                                placeholder={t(
                                    "product-enhancement.request-form.problem-description.placeholder",
                                )}
                                tooltip={t(
                                    "product-enhancement.request-form.problem-description.tooltip",
                                )}
                                validator={notBlankValidator}
                                rows="3"
                                allowClear={false}
                            />
                            {/* <Forms.Fields.TextArea
                                // required
                                name="description"
                                label={t(
                                    "product-enhancement.request-form.description.label"
                                )}
                                placeholder={t(
                                    "product-enhancement.request-form.description.placeholder"
                                )}
                                rows="2"
                                // validator={notBlankValidator}
                                // allowClear={false}
                            /> */}
                            <Forms.Fields.Select
                                required
                                name="customerPriority"
                                data-test="priority-label"
                                label={t(
                                    "product-enhancement.request-form.customer-priority.label",
                                )}
                                placeholder={t(
                                    "product-enhancement.request-form.customer-priority.placeholder",
                                )}
                                allowClear={false}
                                options={CUSTOMER_PRIORITY_OPTIONS}
                                showSearch
                                // validator={Forms.pmValidators.isRequired}
                                initialValue={CP_DEFAULT}
                            />
                            <Forms.Fields.Select
                                required
                                name="components[]"
                                data-test="priority-dropdown"
                                label={t(
                                    "product-enhancement.request-form.components.label",
                                )}
                                placeholder={t(
                                    "product-enhancement.request-form.components.placeholder",
                                )}
                                tooltip={t(
                                    "product-enhancement.request-form.components.tooltip",
                                )}
                                mode="multiple"
                                allowClear={false}
                                showSearch
                                validator={Forms.validators.failOnFirst([
                                    Forms.pmValidators.isRequired,
                                    Forms.pmValidators.createMinLengthValidation(
                                        1,
                                        {
                                            messageKey:
                                                "general.validation.array.min-length",
                                        },
                                    ),
                                ])}
                                options={COMPONENTS_OPTIONS}
                                // {...getLoadableSelectProps(
                                //     componentsOptionsResource.loadable
                                // )}
                                // antdItemProps={{ validateStatus: "validating" }} // not working
                            />

                            <Forms.Fields.Upload
                                label={t(
                                    "product-enhancement.request-form.attachments.label",
                                )}
                                name="attachments"
                                data-test="attachments-upload"
                                multiple={true}
                                accept="*"
                            />

                            {/* <Forms.Fields.Select
                                // required
                                name="customers"
                                label={t(
                                    "product-enhancement.request-form.customers.label"
                                )}
                                placeholder={t(
                                    "product-enhancement.request-form.customers.placeholder"
                                )}
                                mode="multiple"
                                allowClear={false}
                                showSearch
                                {...getLoadableSelectProps(
                                    customersOptionsResource.loadable
                                )}
                            /> */}
                            {!!accountsOptions.length && (
                                <Forms.Fields.Select
                                    required
                                    name="customers[]"
                                    data-test="customers-input"
                                    label={t("general.account")}
                                    placeholder={t(
                                        "product-enhancement.request-form.customers.placeholder",
                                    )}
                                    tooltip={t(
                                        "product-enhancement.request-form.customers.tooltip",
                                    )}
                                    mode="multiple"
                                    allowClear={false}
                                    showSearch
                                    options={accountsOptions}
                                    disabled={hasOnlyOneAccount}
                                    validator={Forms.pmValidators.isRequired}
                                />
                            )}
                            <Forms.Fields.Checkbox
                                required
                                name="consent"
                                data-test="confirm-checkbox"
                                validator={consentValidator}
                                label={
                                    <div>
                                        {t(
                                            "product-enhancement.request-form.consent.label",
                                        )}
                                    </div>
                                }
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.create"),
                                    type: "primary",
                                    formId,
                                    htmlType: "button",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
CreateProductRequestModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    afterSave: PropTypes.func.isRequired,
};
