import {
    Alert,
    Button,
    ButtonGroup,
    Forms,
    H3,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { EditSamlDomainsModal } from "@/components/SingleSignOn/EditSamlDomains.modal";
import {
    isLoading,
    LoadablePropType,
    LoadableRenderer,
    QueryResourcePropType,
} from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { CertificateTable } from "./CertificateTable";
import { PfxIdentification } from "./PfxIdentification";

const LOGIN_WITH_OPTIONS = [
    { label: "Email", value: "EMAIL" },
    { label: "SSO Username", value: "SSO_USERNAME" },
];
const ENABLED_OPTIONS = [
    { label: "SSO only", value: "SSO_ONLY" },
    { label: "SSO + Username/Password", value: "SSO_AND_CREDENTIALS" },
    { label: "Disabled", value: "DISABLED" },
];

const isMissingPfxIdentification = axiosError =>
    axiosError?.response?.status === 404;

export const SingleSignOnForm = ({
    isAdmin,
    initialValuesLoadable,
    onSubmit,
    onDomainEdit,
    accountsLoadable,
    emailsLoadable,
    onAccountChange,
    projectId,
    projectName,
    certificatesResource,
    pfxCertificatesResource,
    pfxIdentificationLoadable,
    generatePfxIdentification,
}) => {
    const initialValues = isAdmin
        ? initialValuesLoadable.valueMaybe()
        : initialValuesLoadable.valueMaybe()?.samlReplyingPartyDto;

    const flags = initialValuesLoadable.valueMaybe();

    const { formId, handleSubmit, setValues, setTouched, reset } =
        Forms.useForm({
            onSubmit: async ({ values }) => onSubmit(values),
        });

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues,
            setTouched,
        },
        [initialValuesLoadable],
    );
    const handleAccountChange = useCallback(
        ({ value }) => {
            reset();
            onAccountChange(value);
        },
        [onAccountChange, reset],
    );
    const submitDisabled =
        (isAdmin && isLoading(accountsLoadable)) ||
        (!isAdmin && flags?.isNewSamlConfigurationRequested);
    const accountForm = Forms.useForm();

    const editSamlDomainsModal = useVisibility();

    const isFilledDomain = !isEmpty(initialValues?.domain);

    return (
        <>
            {isAdmin && (
                <Forms.Form formId={accountForm.formId}>
                    <Forms.Fields.Select
                        required
                        name="accountId"
                        label={t("sso.form.accountid.label")}
                        validator={Forms.pmValidators.isRequired}
                        onChange={handleAccountChange}
                        {...getLoadableSelectProps(accountsLoadable)}
                    />
                </Forms.Form>
            )}
            {!!projectId && (
                <LoadableRenderer
                    loadable={pfxIdentificationLoadable}
                    hasError={axiosError =>
                        isMissingPfxIdentification(axiosError) ? (
                            <PfxIdentification
                                onGenerate={generatePfxIdentification}
                            />
                        ) : (
                            <LoadableRenderer.Error>
                                {getErrorMessageFromError(axiosError)}
                            </LoadableRenderer.Error>
                        )
                    }
                    hasValue={pfxIdentification => (
                        <>
                            {flags?.isNewSamlConfigurationRequested ? (
                                <Alert
                                    type="info"
                                    message={t(
                                        "sso.form.submitted.info.for-approval",
                                    )}
                                />
                            ) : null}
                            <PfxIdentification values={pfxIdentification} />
                            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                                {pfxCertificatesResource && (
                                    <>
                                        <H3>Pricefx Certificate</H3>
                                        <CertificateTable
                                            isAdmin={isAdmin}
                                            projectId={projectId}
                                            certificatesLoadable={
                                                pfxCertificatesResource
                                            }
                                            isDeletable={false}
                                        />
                                    </>
                                )}
                                {!isAdmin && (
                                    <Forms.Fields.Select
                                        name="expireNotificationRecipients"
                                        tooltip={t(
                                            "sso.form.expirenotificationrecipients.tooltip",
                                        )}
                                        label={t(
                                            "sso.form.expirenotificationrecipients.label",
                                        )}
                                        placeholder="Select user"
                                        mode="tags"
                                        validator={
                                            Forms.pmValidators.areEmailsValid
                                        }
                                        {...getLoadableSelectProps(
                                            emailsLoadable,
                                        )}
                                    />
                                )}
                                <H3>{projectName} Certificate</H3>
                                <CertificateTable
                                    isAdmin={isAdmin}
                                    projectId={projectId}
                                    certificatesLoadable={certificatesResource}
                                    isDeletable
                                    isAddable
                                />
                                <Forms.Fields.Input
                                    required
                                    name="webSsoUrl"
                                    tooltip={t("sso.form.webssourl.tooltip")}
                                    label={t("sso.form.webssourl.label")}
                                    placeholder=""
                                    validator={Forms.pmValidators.isRequired}
                                />

                                <Forms.Fields.Input
                                    required
                                    name="domain"
                                    tooltip={t("sso.form.domain.tooltip")}
                                    label={t("sso.form.domain.label")}
                                    placeholder=""
                                    validator={Forms.pmValidators.isRequired}
                                    disabled={!isAdmin && isFilledDomain}
                                />

                                {!isAdmin ? (
                                    <>
                                        {!flags?.isDomainChangeRequested &&
                                        isFilledDomain &&
                                        !flags?.isNewSamlConfigurationRequested ? (
                                            <Button
                                                onClick={
                                                    editSamlDomainsModal.show
                                                }
                                                label={t("general.edit")}
                                                style={{ marginTop: "-10px" }}
                                            />
                                        ) : null}

                                        {flags?.isDomainChangeRequested ? (
                                            <div style={{ maxWidth: "395px" }}>
                                                <Alert
                                                    type="info"
                                                    message={t(
                                                        "sso.form.submitted.info.for-approval-item",
                                                    )}
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                                {/* <Forms.Fields.List
                                    required
                                    name="domain"
                                    tooltip={t("sso.form.domain.tooltip")}
                                    label={t("sso.form.domain.label")}
                                    placeholder=""
                                    validator={Forms.pmValidators.isRequired}
                                    initialValue={[
                                        { domain: "pricefx.eu" },
                                        { domain: "pricefx.com" },
                                    ]}
                                /> */}
                                <Forms.Fields.Input
                                    required
                                    name="entityId"
                                    tooltip={t("sso.form.entityid.tooltip")}
                                    label={t("sso.form.entityid.label")}
                                    placeholder=""
                                    validator={Forms.pmValidators.isRequired}
                                />
                                <Forms.Fields.Radio
                                    required
                                    name="loginWith"
                                    label={t("sso.form.loginwith.label")}
                                    options={LOGIN_WITH_OPTIONS}
                                    validator={Forms.pmValidators.isRequired}
                                />
                                <Forms.Fields.Radio
                                    required
                                    name="loginOptions"
                                    label={t("sso.form.enabled.label")}
                                    options={ENABLED_OPTIONS}
                                    validator={Forms.pmValidators.isRequired}
                                />
                                <ButtonGroup>
                                    <Forms.SubmitButton
                                        disabled={submitDisabled}
                                        label={
                                            isAdmin
                                                ? t("general.save")
                                                : isFilledDomain
                                                ? t("general.save")
                                                : t(
                                                      "general.submit-for-approval",
                                                  )
                                        }
                                        type="primary"
                                        htmlType="submit"
                                    />
                                </ButtonGroup>
                            </Forms.Form>
                            <EditSamlDomainsModal
                                projectId={projectId}
                                visible={editSamlDomainsModal.visible}
                                onClose={editSamlDomainsModal.hide}
                                onSubmit={() => {
                                    editSamlDomainsModal.hide();
                                    onDomainEdit();
                                }}
                                initialValues={initialValues}
                            />
                        </>
                    )}
                />
            )}
        </>
    );
};

SingleSignOnForm.propTypes = {
    isAdmin: PropTypes.bool,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    certificatesResource: QueryResourcePropType().isRequired,
    pfxCertificatesResource: QueryResourcePropType(),
    initialValuesLoadable: LoadablePropType(PropTypes.object),
    onSubmit: PropTypes.func.isRequired,
    onDomainEdit: PropTypes.func,
    accountsLoadable: LoadablePropType(PropTypes.array.isRequired),
    emailsLoadable: LoadablePropType(PropTypes.array),
    onAccountChange: PropTypes.func,
    pfxIdentificationLoadable: LoadablePropType(
        PropTypes.shape({
            entityId: PropTypes.string,
            redirectUrl: PropTypes.string,
        }),
    ).isRequired,
    generatePfxIdentification: PropTypes.func.isRequired,
};
