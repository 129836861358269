import { Button, Gap, H3, P } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { NotificationType } from "@/components/Header/components/Notifications/types";
import SSEType from "@/components/ServerSideEvents/types";
import useSSE from "@/components/ServerSideEvents/useSSE.hook";
import { expandParam } from "@/components/TableLocalFiltered/expandParam";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { LocationLink } from "@/modules/router";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";

const UploadFinishTemplate = ({ heading, text, button }) => (
    <>
        <H3 data-test="upload-finish-header">{heading}</H3>
        <Gap />
        <P>{text}</P>
        {button}
    </>
);

const DataUploadsLinkButton = props => {
    const { accountAppLocations } = useDic();

    return (
        <LocationLink $location={accountAppLocations.partitionUploadsLocation}>
            <Button label={t("data-upload.finish.button.back")} {...props} />
        </LocationLink>
    );
};

const DataUploadsHistoryLinkButton = ({ uploadId, fileId, ...props }) => {
    const { accountAppLocations } = useDic();

    return (
        <LocationLink
            $location={accountAppLocations.partitionUploadHistoryLocation}
            $params={{
                uploadId,
                expand: expandParam.stringify(["id", fileId]),
            }}
        >
            <Button label={t("data-upload.finish.button.detail")} {...props} />
        </LocationLink>
    );
};

const useDataLoadFileQuery = ({ partitionId, uploadId, fileId, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(
                          `/api/partitions/${partitionId}/data-uploads/${uploadId}/files/${fileId}`,
                      )
                      .then(getData),
        [axiosService, canFetch, fileId, partitionId, uploadId],
    );
};

export const UploadFinish = ({ taskId }) => {
    const [data, setData] = useState({});
    useSSE(SSEType.NOTIFICATION, notification => {
        const { type, data = {} } = notification;

        if (type === NotificationType.TASK && data.id === taskId) {
            setData(data);
        }
    });
    const dataLoadFileQuery = useDataLoadFileQuery({
        canFetch: ["DONE", "FAILED"].includes(data.status),
        partitionId: data.context?.partitionId,
        uploadId: data.context?.uploadId,
        fileId: data.context?.uploadedFileId,
    });
    const { recordCount = "", failedInputRecords = "" } =
        dataLoadFileQuery.loadable.valueMaybe() || {};

    if (data.status === "DONE") {
        return (
            <UploadFinishTemplate
                heading={t("data-upload.finish.finished.heading")}
                text={
                    <span data-test="finish-text-done">
                        {t("data-upload.finish.success.text", {
                            recordsCount: recordCount,
                        })}
                    </span>
                }
                button={
                    <DataUploadsLinkButton data-test="finish-button-done" />
                }
            />
        );
    }

    if (data.status === "FAILED") {
        return (
            <UploadFinishTemplate
                heading={t("data-upload.finish.finished.heading")}
                text={
                    <span data-test="finish-text-failed">
                        {t("data-upload.finish.error.text", {
                            recordsCount: failedInputRecords,
                        })}
                    </span>
                }
                button={
                    <DataUploadsHistoryLinkButton
                        uploadId={data.context?.uploadId}
                        fileId={data.context?.uploadedFileId}
                        data-test="finish-button-failed"
                    />
                }
            />
        );
    }

    return (
        <UploadFinishTemplate
            heading={t("data-upload.finish.pending.heading")}
            text={
                <span data-test="finish-text-pending">
                    {t("data-upload.finish.pending.text")}
                </span>
            }
            button={<DataUploadsLinkButton data-test="finish-button-pending" />}
        />
    );
};

UploadFinish.propTypes = {
    taskId: PropTypes.number.isRequired,
};
