import { ButtonMenu, DatePicker, Label } from "@/components/DesignSystem";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { QuerySearch } from "@/components/Integrations/Dashboard/Tabs/QuerySearch/QuerySearch.component";
import SelectColumnsDialog from "@/components/Integrations/Dashboard/Tabs/SelectColumnsDialog";
import { useTableSettings } from "@/components/PageLayout/useSetMenuItems.hook";
import { elkQueryService } from "@/services/elkQuery.service";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { PageLayoutContext } from "../PageLayout/PageLayoutContext";
import styles from "./SearchPanel.style.less";

const { RangePicker } = DatePicker;

const SearchPanel = ({
    accountId,
    entityId,
    entityType,

    availableColumns,
    selectedColumns,

    onColumnsChange,
    onDateRangeChange,
    onSearch,

    query,
    dateFrom,
    dateTo,

    visible = true,
    showEmbeddedActionButtons,
    menuItemsId = JSON.stringify({
        id: "_search-panel",
        availableColumns,
        selectedColumns,
    }),
}) => {
    const { actionButtons } = useContext(PageLayoutContext);
    const isValid = query => {
        return elkQueryService.isValid(accountId, entityId, entityType, query);
    };

    const columnsModal = useVisibility(false);

    const items = useMemo(
        () => [
            {
                anchor: t("select-columns.button.columns"),
                onClick: columnsModal.show,
                "data-test": "search-panel.colmnns",
            },
        ],
        [columnsModal.show],
    );
    useTableSettings({
        id: menuItemsId,
        visible,
        items,
    });

    return (
        <div className={styles.wrapper}>
            <QuerySearch
                accountId={accountId}
                entityId={entityId}
                entityType={entityType}
                initialQueryString={query}
                isValid={isValid}
                onSearch={onSearch}
            />

            <label htmlFor="searchPanel-dateRange">
                <Label label={t("search-panel.date-range.label")} />
                <RangePicker
                    id="searchPanel-dateRange"
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                    placeholder={[
                        t("search-panel.date-range.placeholder-from"),
                        t("search-panel.date-range.placeholder-to"),
                    ]}
                    defaultValue={[dateFrom, dateTo]}
                    onOk={onDateRangeChange}
                />
            </label>
            {showEmbeddedActionButtons && (
                <ButtonMenu
                    buttons={actionButtons}
                    buttonSize="default"
                    ellipsis={false}
                    _trackingDisabled // TODO: UC mutates buttons and adds sfdc_table_showTableSetting inside settings button
                />
            )}

            <SelectColumnsDialog
                visible={columnsModal.visible}
                availableColumns={availableColumns}
                selectedColumns={selectedColumns}
                onConfirm={selected => {
                    columnsModal.hide();
                    onColumnsChange(selected);
                }}
                onClose={columnsModal.hide}
            />
        </div>
    );
};

SearchPanel.propTypes = {
    accountId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    availableColumns: PropTypes.array.isRequired,
    selectedColumns: PropTypes.object.isRequired,
    onColumnsChange: PropTypes.func.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
    query: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    onSearch: PropTypes.func,
    visible: PropTypes.bool,
};

export default SearchPanel;
