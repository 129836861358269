import { useDic } from "@/components/Dic/useDic.hook";
import { useWithWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    unwrapAxiosError,
    useMutationLoadableWithNotification,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { message } from "antd";
import { noop, tap } from "lodash/fp";

export const useRollbackParams = ({
    canFetch,
    accountId,
    deploymentId,
    partitionId,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(
                          `/api/accounts/${accountId}/package-deployments/${deploymentId}/partitions/${partitionId}`,
                      )
                      .then(getData)
                      .catch(e => {
                          throw unwrapAxiosError(e);
                      })
                : pendingPromise(),
        [canFetch, axiosService, accountId, deploymentId, partitionId],
    );
};

export const useClustersOptionsQuery = partitionId => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            partitionId
                ? axiosService
                      .get(`/api/partition-assets/${partitionId}/clusters`)
                      .then(getData)
                      .then(contacts =>
                          contacts.map(cluster => ({
                              label: cluster.name,
                              value: cluster.name,
                          })),
                      )
                : waitForValue(),
        [axiosService, partitionId],
    );
};
export const useSalesforceContactsOptionsQuery = partitionId => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            partitionId
                ? axiosService
                      .get(`/api/partition-assets/${partitionId}/contacts`)
                      .then(getData)
                      .then(contacts =>
                          contacts.map(contact => ({
                              label: `${contact.name}${
                                  contact.title ? ` (${contact.title})` : ""
                              }`,
                              value: contact.id,
                          })),
                      )
                : waitForValue(),
        [axiosService, partitionId],
    );
};

export const useCopyPartitionResource = ({ partitionId, afterSuccess }) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    const withWorkflow = useWithWorkflowFeedback();

    return useMutationLoadableWithNotification(
        async values =>
            withWorkflow(() =>
                axiosService.post(
                    `/api/partition-assets/${partitionId}/copy-partition-jobs`,
                    values,
                ),
            ).then(currentAfterSuccess),
        [axiosService, currentAfterSuccess, partitionId, withWorkflow],
        "copy-partition.success",
        "copy-partition.error",
    );
};

export const usePartitionQuery = ({ accountId, partitionId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(async () =>
        axiosService
            .get(
                `/api/projects/${accountId}/partition-assets/${partitionId}?withPfxInfo=true`,
            )
            .then(getData),
    );
};

export const useDeletePartitionMutation = ({ afterSuccess = noop }) => {
    const { partitionService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflow = useWithWorkflowFeedback();

    return useMutationLoadableWithNotification(
        async ({ accountId, partitionId }) =>
            withWorkflow(async () =>
                partitionService.deletePartition(accountId, partitionId),
            ).then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, partitionService, withWorkflow],
        "partition-relete-request.started",
    );
};

export const useEngSupportUserInfoQuery = (partitionId, canFetch) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(
                          `/api/partition-assets/${partitionId}/eng-support-user-info`,
                      )
                      .then(getData)
                      .catch(e => {
                          if (e.response.status === 400)
                              return {
                                  canCreateNew: false,
                                  canUse: false,
                                  pfxLoginInfo: null,
                              };
                          message.error(getErrorMessageFromError(e));
                          throw e;
                      })
                : waitForValue(),
        [axiosService, canFetch, partitionId],
    );
};
