import { useDic } from "@/components/Dic/useDic.hook";
import { useWithWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";

export const useRequestEngSupportUserMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    const withWorkflow = useWithWorkflowFeedback();

    return useMutationLoadableWithNotification(
        async values => {
            withWorkflow(() => {
                const payload = {
                    reason: values.reason,
                    expiration:
                        values.expiration?.toISOString?.() ?? values.expiration,
                };

                return axiosService.post(
                    `/api/partition-assets/${partitionId}/request-eng-support-user`,
                    payload,
                );
            }).then(currentAfterSuccess);
        },
        [axiosService, currentAfterSuccess, partitionId, withWorkflow],
        "partition.request-eng-support-user.created",
    );
};
