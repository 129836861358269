import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
    cleanButtonData,
    setBreadcrumbButtons,
} from "../BreadcrumbButton/duck/actions";

// TODO: stack with ids as in useSetAlert, useFooterButtons?
export function useBreadcrumbButtons(getButtons, effectDeps = [], debugMsg) {
    const _dispatch = useDispatch();
    const dispatch = (...args) => {
        if (debugMsg)
            console.log("[useBreadcrumbButtons.dispatch]", debugMsg, ...args);
        return _dispatch(...args);
    };

    useEffect(() => {
        const buttons = getButtons?.();
        if (!buttons?.length) {
            dispatch(cleanButtonData());
        } else {
            dispatch(setBreadcrumbButtons(buttons));
        }

        return () => dispatch(cleanButtonData());
    }, effectDeps);
}
