import { getSwitchChangeSuccessMsg } from "@/components/DesignSystem/SwitchAsync/helpers";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadable, useQueryLoadable } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { getData } from "@/services/utils";
import { noop, tap } from "lodash/fp";
import qs from "qs";
import { useAlertEffect } from "../PageLayout/useAlertEffect.hook";
import { useWithWorkflowWithReasonMaybe } from "../WorkFlow/workflowNotification";
import { t } from "@/translations";

export const useEventSchedulersQuery = ({ accountId, reloadToken }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-schedulers`)
                .then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadToken, accountId, axiosService],
    );
};

export const useCreateEventSchedulerMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe({
        noWorkflowSuccess: getSwitchChangeSuccessMsg,
    });

    return useMutationLoadable(
        async payload =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-schedulers`,
                    { ...payload, ...wfValues },
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            accountId,
            afterSuccessCurrent,
            axiosService,
            withWorkflowWithReasonMaybe,
        ],
    );
};

export const useEventSchedulerQuery = ({ accountId, schedulerId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}`,
                )
                .then(getData),
        [accountId, axiosService, schedulerId],
    );
};

export const useUpdateEventSchedulerMutation = ({
    accountId,
    schedulerId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe({
        noWorkflowSuccess: getSwitchChangeSuccessMsg,
    });

    return useMutationLoadable(
        async payload =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}`,
                    { ...payload, ...wfValues },
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            accountId,
            afterSuccessCurrent,
            axiosService,
            schedulerId,
            withWorkflowWithReasonMaybe,
        ],
    );
};

export const useDeleteEventSchedulerMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe();

    return useMutationLoadable(
        async ({ schedulerId }) =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}/delete`,
                    { ...wfValues },
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            accountId,
            afterSuccessCurrent,
            axiosService,
            withWorkflowWithReasonMaybe,
        ],
    );
};

export const useChangeEventSchedulerStatusMutation = ({
    afterSuccess = noop,
} = {}) => {
    const { axiosService } = useDic();
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe({
        noWorkflowSuccess: getSwitchChangeSuccessMsg,
    });
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadable(
        async ({ schedulerId, enabled, accountId }) =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-schedulers/${schedulerId}/enable`,
                    { enabled, ...wfValues },
                ),
            ).then(afterSuccessCurrent),
        [afterSuccessCurrent, axiosService, withWorkflowWithReasonMaybe],
    );
};

const useEventSchedulerApprovalWFRunningQuery = ({
    accountId,
    schedulerName,
    schedulerId,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/accounts/${accountId}/event-schedulers/is-approval-wf-running?${qs.stringify(
                        { schedulerName, schedulerId },
                    )}`,
                )
                .then(getData),
        [accountId, axiosService, schedulerName, schedulerId],
    );
};

export const useSchedulerApprovalRunning = ({
    schedulerName,
    schedulerId,
    withAlert = true,
}) => {
    const { accountId } = useAccountAppParams();
    const query = useEventSchedulerApprovalWFRunningQuery({
        accountId,
        schedulerName,
        schedulerId,
    });
    const isRunning = query.loadable.valueMaybe();
    const alertVisible = withAlert && isRunning;

    const alertDef = {
        type: "info",
        message: t("general.workflow-approval-pending.title"),
        description: t("general.workflow-approval-pending.description", {
            entity: t("general.scheduler"),
        }),
    };
    useAlertEffect(alertVisible, () => alertDef);

    return {
        isRunning,
        alert: isRunning ? alertDef : undefined,
    };
};
