import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { GroupForm, useGroupForm } from "./GroupForm";

export const NewGroupModal = ({ visible, onClose, reloadGroups }) => {
    const { actionButtons, ...rest } = useGroupForm({
        onCancel: onClose,
        initialValues: undefined,
        afterSave: () => {
            reloadGroups();
            onClose();
        },
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("group-form.title.new-group")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <GroupForm {...rest} inputWidth="max" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={<ButtonMenu buttons={actionButtons} />}
                />
            </UnityLayout>
        </Modal>
    );
};

NewGroupModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    reloadGroups: PropTypes.func.isRequired,
};
