import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useWithWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadable,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useCalculationEnginesQuery = ({ partitionId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/partition-assets/${partitionId}/calculation-engines`)
                .then(getData),
        [axiosService, partitionId],
    );
};

export const useCalculationEngineQuery = ({ engineId, partitionId, isNew }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            isNew
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/partition-assets/${partitionId}/calculation-engines/${engineId}`,
                      )
                      .then(getData),
        [axiosService, engineId, isNew, partitionId],
    );
};

export const useSubmitCalculationEngineMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflow = useWithWorkflowFeedback();

    return useMutationLoadable(
        async data =>
            withWorkflow(() =>
                axiosService.post(
                    `/api/partition-assets/${partitionId}/calculation-engines`,
                    data,
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, partitionId, withWorkflow],
    );
};

export const useDeleteCalculationEngineMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        ({ engineId }) =>
            axiosService
                .delete(
                    `/api/partition-assets/${partitionId}/calculation-engines/${engineId}`,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, partitionId],
    );
};

export const useCalculationEnginePresetsQuery = ({ partitionId } = {}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/partition-assets/${partitionId}/calculation-engine-presets`,
                )
                .then(getData),
        [axiosService, partitionId],
    );
};
