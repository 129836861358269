import { ButtonMenu, Gap, Modal, UnityLayout } from "@/components/DesignSystem";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useHasPermission } from "@/security/hooks/useHasPermission.hook";
import { GROUP_MANAGEMENT_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { GroupForm, useGroupForm } from "./GroupForm";
import { GroupUsers } from "./GroupUsers";
import {
    useGroupAllowedUsersResource,
    useGroupResource,
    useGroupUsersResource,
} from "./loadables";

export const EditGroupModal = ({
    visible,
    groupName,
    onClose,
    reloadGroups,
}) => {
    const groupResource = useGroupResource({
        groupName,
        canFetch: visible,
    });
    const groupUsersResource = useGroupUsersResource({
        groupName,
        canFetch: visible,
    });
    const groupAllowedUsersResource = useGroupAllowedUsersResource({
        groupName,
        canFetch: visible,
    });

    const reloadGroupUsers = useCallback(() => {
        groupUsersResource.reload();
        groupAllowedUsersResource.reload();
        reloadGroups?.();
    }, [reloadGroups, groupUsersResource, groupAllowedUsersResource]);

    const { actionButtons, ...groupFormProps } = useGroupForm({
        onCancel: onClose,
        initialValues: groupResource.loadable.valueMaybe(),
        afterSave: () => {
            groupResource.reload();
            reloadGroupUsers();
            onClose();
        },
    });

    const isADManaged = groupFormProps.initialValues?.adManaged ?? false;

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={t("edit-group.title")} />
                <UnityLayout.Content padding={[true, true]}>
                    <LoadableRenderer
                        loadable={groupResource.loadable}
                        hasValue={() => (
                            <GroupForm inputWidth="max" {...groupFormProps} />
                        )}
                    />
                    <Gap />
                    <LoadableRenderer
                        loadable={useComposeLoadablesMemoized([
                            groupResource.loadable,
                            groupUsersResource.loadable,
                            groupAllowedUsersResource.loadable,
                        ])}
                        hasValue={([group, groupUsers, groupAllowedUsers]) => (
                            <GroupUsers
                                group={group}
                                groupUsers={groupUsers}
                                groupAllowedUsers={groupAllowedUsers}
                                canEdit={!isADManaged}
                                reload={reloadGroupUsers}
                            />
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={<ButtonMenu buttons={actionButtons} />}
                />
            </UnityLayout>
        </Modal>
    );
};
