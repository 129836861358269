import { t } from "@/translations";
import React from "react";
import { AlertCellWithPopover } from "../AlertCellWithPopover/AlertCellWithPopover";
import { ButtonMenu, Modal, Table, UnityLayout } from "../DesignSystem";
import { fieldTypes } from "../DesignSystem/Table/constants";

const ERROR_COLUMNS = [
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.errors.column.timestamp"),
        name: "timestamp",
        render: text => (
            <AlertCellWithPopover iconVisible type="critical" content={text} />
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("isv.overview.overview.errors.column.message"),
        name: "message",
    },
];

export const ISVErrorsModal = ({ onClose, record }) => {
    const dataSource = [
        {
            timestamp: new Date().toISOString(),
            message: "Mock message",
        },
    ];
    return (
        <Modal visible={!!record} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("isv.overview.overview.column.errors")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {!!record && (
                        <Table
                            rowKey="timestamp"
                            columns={ERROR_COLUMNS}
                            dataSource={dataSource}
                            pagination={false}
                            // fixed
                            // fixedHeight={325}
                        />
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: onClose,
                                    type: "primary",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
