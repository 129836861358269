import { SecurityContext } from "@/security/authorization";
import {
    EO_EDIT_PERMISSION,
    includesPermissionInAssetTree,
} from "@/security/permission.utils";
import { useContext } from "react";

export const useHasEditPermission = ({
    permissions = [EO_EDIT_PERMISSION],
} = {}) => {
    const securityContext = useContext(SecurityContext);
    const hasPermission = includesPermissionInAssetTree(
        securityContext,
        permissions,
    );

    return hasPermission;
};
