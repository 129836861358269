import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import { WorkflowType } from "../../constants/CommonConstats";

export const approvalsColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("account.workflows.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.OPTION,
        label: t("account.workflows.header.action"),
        dateIndex: "type",
        name: "workflowRequestData.wfType",
        options: [
            {
                label: "All",
                value: null,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.CREATE_PARTITION_APPROVAL,
                ),
                value: WorkflowType.CREATE_PARTITION_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.DELETE_CLUSTER_APPROVAL,
                ),
                value: WorkflowType.DELETE_CLUSTER_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.PERMISSION_ASSIGNMENT_APPROVAL,
                ),
                value: WorkflowType.PERMISSION_ASSIGNMENT_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL,
                ),
                value: WorkflowType.DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.DELETE_PARTITION_APPROVAL,
                ),
                value: WorkflowType.DELETE_PARTITION_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.CALCULATION_ENGINE_APPROVAL,
                ),
                value: WorkflowType.CALCULATION_ENGINE_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.PARTITION_PROVISIONING_APPROVAL,
                ),
                value: WorkflowType.PARTITION_PROVISIONING_APPROVAL,
            },
            {
                label: WorkflowType.translate(WorkflowType.SAML_SSO_APPROVAL),
                value: WorkflowType.SAML_SSO_APPROVAL,
            },
            {
                label: WorkflowType.translate(
                    WorkflowType.EVENT_ORCHESTRATION_APPROVAL,
                ),
                value: WorkflowType.EVENT_ORCHESTRATION_APPROVAL,
            },
            {
                label: WorkflowType.translate(WorkflowType.COPILOT_APPROVAL),
                value: WorkflowType.COPILOT_APPROVAL,
            },
        ],
        render: (text, record) => WorkflowType.translate(record.type),
    },

    {
        type: fieldTypes.TEXT,
        label: t("account.workflows.header.asset"),
        canSort: false,
        canFilter: false,
        name: "workflowRequestData.data",
        render: (_, record) => record.asset,
    },
    {
        type: fieldTypes.TEXT,
        label: t("account.workflows.header.target"),
        canSort: false,
        canFilter: false,
        name: "workflowRequestData.target",
        render: (_, record) => record.target,
    },
    {
        type: fieldTypes.TEXT,
        label: t("account.workflows.header.created-by"),
        name: "workflowRequestData.user.username",
        render: (_, record) => record.createdBy,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("account.workflows.header.created-at"),
        name: "workflowRequestData.createdAt",
        render: (text, record) => (
            <FormattedDateTime fromNow>{record.createdAt}</FormattedDateTime>
        ),
    },
];
