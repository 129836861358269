import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

export const routesColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.routes.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.routes.header.uri"),
        name: "fromEndpointUri",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.routes.header.description"),
        name: "description",
    },
];

export const mappersColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.mappers.header.mapperName"),
        name: "mapperName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.mappers.header.in"),
        name: "in",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.mappers.header.out"),
        name: "out",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.mappers.header.converter"),
        name: "converter",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.mappers.header.converterExpression"),
        name: "converterExpression",
    },
];

export const scheduledJobsColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.scheduled-jobs.header.name"),
        name: "name",
        width: 200,
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.scheduled-jobs.header.uri"),
        name: "fromEndpointUri",
    },
];

export const convertersColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.converters.header.name"),
        name: "id",
        width: 200,
    },
    // Temporarily removed until backend implementation #PFIMCORE-1332
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("instance.documentation.converters.header.usage"),
    //     name: "usage",
    // },
];

export const beansColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.beans.header.name"),
        name: "name",
        width: 200,
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.beans.header.class-name"),
        name: "entity.className",
        render: (_, record) => record?.data?.className,
    },
];

export const resourcesColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.resources.header.name"),
        name: "name",
        width: 200,
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.resources.header.filename"),
        name: "filename",
    },
];

export const filtersColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.filters.header.id"),
        name: "id",
        width: 200,
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.filters.header.sort-by"),
        name: "sortBy",
        width: 200,
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.filters.header.result-fields"),
        name: "resultFields",
    },
];

export const connectionsColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.connections.header.id"),
        name: "id",
        width: 200,
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.connections.header.discriminator"),
        name: "discriminator",
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance.documentation.connections.header.uri"),
        name: "fromEndpointUri",
        width: 200,
    },
];
