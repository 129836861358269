import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Button, Forms, Gap } from "@/components/DesignSystem";
import {
    useEnableDeeplinksMutation,
    useEnableDeeplinksQuery,
} from "@/components/EnableDeeplinks/loadables";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useSetPageDescription } from "@/components/PageLayout/useSetPageDescription.hook";
import { hasValue, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import moment from "moment";
import React from "react";

const LAYOUT = [{ width: "300px" }, { width: "400px" }, { width: "25px" }];

export const EnableDeeplinksPage = () => {
    useHideBackButton();

    const enableDeeplinksQuery = useEnableDeeplinksQuery();
    const lastBulkSending =
        enableDeeplinksQuery.loadable.valueMaybe()?.lastBulkSending;

    const enableDeeplinksMutation = useEnableDeeplinksMutation({
        afterSuccess: enableDeeplinksQuery.reload,
    });

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({ values }) => {
            enableDeeplinksMutation.mutate(values);
        },
    });

    const isSubmitting = Forms.useFormIsSubmitting(formId);

    useBreadcrumbButtons(
        () =>
            hasValue(enableDeeplinksQuery) && [
                {
                    type: "primary",
                    label: t("enable-deeplinks.form.submit"),
                    onClick: handleSubmit,
                },
            ],
        [enableDeeplinksQuery, handleSubmit],
    );

    useSetPageDescription(
        lastBulkSending
            ? t("enable-deeplinks.last-bulk-sending", {
                  lastBulkSending: moment
                      .utc(lastBulkSending)
                      .local()
                      .toString()
                      .replace(/GMT.*/, "")
                      .trim(),
              })
            : null,
    );

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <LoadableRenderer
                loadable={enableDeeplinksQuery.loadable}
                hasValue={({ enableDeepLinks }) => {
                    return (
                        <div style={{ maxWidth: "767px" }}>
                            <Forms.FieldGrid layout={LAYOUT}>
                                <Forms.List
                                    name={"enableDeepLinks"}
                                    initialValue={enableDeepLinks}
                                >
                                    {({ rows, add, remove }) => (
                                        <>
                                            {rows.map(({ id, fieldProps }) => (
                                                <Forms.FieldGrid.Row key={id}>
                                                    <Forms.Fields.Input
                                                        label={t(
                                                            "enable-deeplinks.form.label.page-name",
                                                        )}
                                                        {...fieldProps(
                                                            "pageName",
                                                        )}
                                                        validator={
                                                            Forms.pmValidators
                                                                .isRequired
                                                        }
                                                    />
                                                    <Forms.Fields.Input
                                                        label={t(
                                                            "enable-deeplinks.form.label.url",
                                                        )}
                                                        {...fieldProps("url")}
                                                        validator={
                                                            Forms.pmValidators
                                                                .isRequired
                                                        }
                                                    />
                                                    <Forms.UIField name="actions">
                                                        <DeleteRowButton
                                                            danger
                                                            onClick={() =>
                                                                remove(id)
                                                            }
                                                        />
                                                    </Forms.UIField>
                                                </Forms.FieldGrid.Row>
                                            ))}
                                            <Gap size="small" />
                                            <Button
                                                icon={Plus}
                                                label={t(
                                                    "enable-deeplinks.form.add-new-link",
                                                )}
                                                onClick={() => add()}
                                                disabled={isSubmitting}
                                            />
                                        </>
                                    )}
                                </Forms.List>
                            </Forms.FieldGrid>
                        </div>
                    );
                }}
            />
        </Forms.Form>
    );
};
