import { Table, Text } from "@/components/DesignSystem";
import { CollapseContent } from "@/components/DesignSystem/Collapse/CollapseContent";
import { useDocumentationQuery } from "@/components/Integrations/Dashboard/Tabs/Documentation/loadables";
import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { LoadableRenderer, isLoading } from "@/modules/loadable";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t } from "@/translations";
import { Collapse } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import {
    beansColumns,
    convertersColumns,
    mappersColumns,
    routesColumns,
    scheduledJobsColumns,
} from "./documentation.columns";
import styles from "./Documentation.less";

export const Documentation = ({
    accountId,
    instanceId,
    imVersion,
    instanceName,
    visible,
}) => {
    const setErrorAlert = useSetErrorAlert();

    const documentationQuery = useDocumentationQuery({
        instanceId,
        canFetch: !!instanceId && visible,
        onError: setErrorAlert,
    });

    const dataSource = documentationQuery.loadable.valueMaybe();
    const loading = isLoading(documentationQuery);

    const basicInfo = useUserBasicInfo();

    const accountName = useMemo(
        () => basicInfo.projects?.find(p => p.id === accountId)?.name,
        [basicInfo.projects, accountId],
    );

    const mappersDataSource = (dataSource?.mappers || []).flatMap(mapper =>
        mapper.data
            ? mapper.data.properties.map(property => ({
                  mapperName: mapper.name,
                  in: property.in,
                  out: property.out,
                  converter: property.converterRef,
                  converterExpression: property.converterExpression?.expression,
              }))
            : {
                  mapperName: mapper.name,
                  alerts: {
                      mapperName: `${mapper?.deployment?.status}: ${
                          mapper?.deployment?.message ??
                          mapper?.deployment?.code
                      }`,
                      mapperName_ALERT_TYPE: "YELLOW",
                  },
              },
    );

    return (
        <div style={{ paddingLeft: 25, paddingRight: 24 }}>
            <LoadableRenderer
                loadable={documentationQuery.loadable}
                hasValue={() => (
                    <Collapse
                        bordered={false}
                        defaultActiveKey={[
                            "overview",
                            "routes",
                            "scheduled-jobs",
                            "mappers",
                            "converters",
                            "beans",
                            "resources",
                            "filters",
                            "connections",
                        ]}
                    >
                        <Collapse.Panel
                            header={t("instance.documentation.overview.label")}
                            key="overview"
                        >
                            <CollapseContent>
                                <div className={styles.overviewContainer}>
                                    <div className={styles.info}>
                                        <Text>
                                            <strong>
                                                {t(
                                                    "instance.documentation.overview.project-name",
                                                )}
                                            </strong>
                                        </Text>
                                        <Text>{accountName ?? "-"}</Text>
                                    </div>
                                    <div className={styles.info}>
                                        <Text>
                                            <strong>
                                                {t(
                                                    "instance.documentation.overview.im-version",
                                                )}
                                            </strong>
                                        </Text>
                                        <Text>{imVersion}</Text>
                                    </div>
                                    <div className={styles.info}>
                                        <Text>
                                            <strong>
                                                {t(
                                                    "instance.documentation.overview.instance-name",
                                                )}
                                            </strong>
                                        </Text>
                                        <Text>{instanceName}</Text>
                                    </div>
                                </div>
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t("instance.documentation.routes.label")}
                            key="routes"
                        >
                            <CollapseContent>
                                <TableLocalFiltered
                                    columns={routesColumns}
                                    dataSource={dataSource?.routes || []}
                                    loading={loading}
                                    rowKey="name"
                                    pagination={{ pageSize: 10 }}
                                    padding={false}
                                />
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t(
                                "instance.documentation.scheduled-jobs.label",
                            )}
                            key="scheduled-jobs"
                        >
                            <CollapseContent>
                                <div style={{ maxWidth: "600px" }}>
                                    <Table
                                        rowKey="name"
                                        columns={scheduledJobsColumns}
                                        dataSource={
                                            dataSource?.scheduledRoutes || []
                                        }
                                        padding={false}
                                    />
                                </div>
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t("instance.documentation.mappers.label")}
                            key="mappers"
                        >
                            <CollapseContent>
                                <TableLocalFiltered
                                    columns={mappersColumns}
                                    dataSource={mappersDataSource}
                                    loading={loading}
                                    rowKey="name"
                                    pagination={{ pageSize: 10 }}
                                    padding={false}
                                />
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t(
                                "instance.documentation.converters.label",
                            )}
                            key="converters"
                        >
                            <CollapseContent>
                                <div style={{ maxWidth: "600px" }}>
                                    <Table
                                        rowKey="name"
                                        columns={convertersColumns}
                                        dataSource={
                                            dataSource?.converters || []
                                        }
                                        padding={false}
                                    />
                                </div>
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t("instance.documentation.beans.label")}
                            key="beans"
                        >
                            <CollapseContent>
                                <div style={{ maxWidth: "600px" }}>
                                    <Table
                                        rowKey="name"
                                        columns={beansColumns}
                                        dataSource={dataSource?.data || []}
                                        padding={false}
                                    />
                                </div>
                            </CollapseContent>
                        </Collapse.Panel>
                        {
                            // Temporarily removed until backend implementation #PFIMCORE-1332
                            /* <Collapse.Panel
                            header={t("instance.documentation.resources.label")}
                            key="resources"
                        >
                            <CollapseContent>
                                <div style={{ maxWidth: "600px" }}>
                                    <Table
                                        rowKey="name"
                                        columns={resourcesColumns}
                                        dataSource={dataSource?.resources || []}
                                        padding={false}
                                    />
                                </div>
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t("instance.documentation.filters.label")}
                            key="filters"
                        >
                            <CollapseContent>
                                <Table
                                    rowKey="name"
                                    columns={filtersColumns}
                                    dataSource={dataSource?.filters || []}
                                    padding={false}
                                />
                            </CollapseContent>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={t(
                                "instance.documentation.connections.label",
                            )}
                            key="connections"
                        >
                            <CollapseContent>
                                <Table
                                    rowKey="name"
                                    columns={connectionsColumns}
                                    dataSource={dataSource?.connections || []}
                                    padding={false}
                                />
                            </CollapseContent>
                        </Collapse.Panel> */
                        }
                    </Collapse>
                )}
            />
        </div>
    );
};

Documentation.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    imVersion: PropTypes.string,
    instanceName: PropTypes.string,
};
