import WarningAlert from "@/components/Alerts/WarningAlert";
import {
    Button,
    ButtonMenu,
    Gap,
    H4,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t, T } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import {
    useAddUserToGroupsResource,
    useRemoveUserFromGroupResource,
} from "./loadables";

export const createUserGroupsColumns = ({ removeUserFromGroup } = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("user-detail.groups.header.name"),
        name: "name",
        render: (text, record) => (
            <>
                {record.name}
                {removeUserFromGroup && (
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: t(
                                    "user-detail.groups.action.remove.title",
                                ),
                                confirm: {
                                    title: t(
                                        "user-detail.groups.action.remove.confirm.title",
                                    ),
                                    message: t(
                                        "user-detail.groups.action.remove.confirm.message",
                                        {
                                            groupName: record.name,
                                        },
                                    ),
                                    okText: t(
                                        "user-detail.groups.action.remove.confirm.ok",
                                    ),
                                    onConfirm: ({ name }) =>
                                        removeUserFromGroup(name),
                                },
                                color: "red",
                            },
                        ]}
                    />
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("user-detail.groups.header.label"),
        name: "label",
    },
];

const AddUserToGroupModal = ({
    visible,
    onClose,
    user,
    allowedGroups,
    reload,
}) => {
    const [selected, setSelected] = useState([]);
    const rowSelection = useSyncRowSelection(selected, setSelected);
    const addUserToGroupsResource = useAddUserToGroupsResource({
        userId: user?.id,
        afterSuccess: () => {
            setSelected([]);
            reload();
        },
    });
    const handleSubmit = () => addUserToGroupsResource.mutate(selected);
    const userGroupsColumns = useMemo(() => createUserGroupsColumns(), []);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("user-detail.groups.add.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <TableLocalFiltered
                        columns={userGroupsColumns}
                        dataSource={allowedGroups}
                        pagination={false}
                        rowKey="name"
                        rowSelection={rowSelection}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    onClick: handleSubmit,
                                    type: "primary",
                                    disabled:
                                        !selected.length ||
                                        addUserToGroupsResource.loadable
                                            .state === "loading",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

export const UserGroups = ({
    allowedGroups,
    userGroups,
    user,
    canEdit = true,
    reload,
}) => {
    const basicInfo = useUserBasicInfo();
    const loginUserName = basicInfo.userInfo.user.username;

    const removeUserFromGroupResource = useRemoveUserFromGroupResource({
        userId: user?.id,
        afterSuccess: () => setTimeout(reload, 0),
    });

    const userGroupsColumns = useMemo(
        () =>
            createUserGroupsColumns({
                removeUserFromGroup: canEdit
                    ? removeUserFromGroupResource.mutate
                    : undefined,
            }),
        [removeUserFromGroupResource.mutate],
    );

    const addUserToGroupModal = useVisibility(false);

    return (
        <>
            <H4>{t("user-detail.groups.title")}</H4>
            <WarningAlert
                message={t("user-detail.alert.assigned-groups")}
                visible={user.username === loginUserName}
            />
            <Gap size="small" />
            <TableLocalFiltered
                columns={userGroupsColumns}
                dataSource={userGroups}
                pagination={false}
                rowKey="name"
            />
            <Gap size="small" />
            {canEdit && (
                <Button
                    onClick={addUserToGroupModal.show}
                    label={<T id="general.add-to-group" />}
                />
            )}
            <AddUserToGroupModal
                visible={addUserToGroupModal.visible}
                onClose={addUserToGroupModal.hide}
                user={user}
                allowedGroups={allowedGroups}
                reload={reload}
            />
        </>
    );
};

UserGroups.propTypes = {
    userGroups: PropTypes.array,
    allowedGroups: PropTypes.array,
    user: PropTypes.object,
    canEdit: PropTypes.bool,
    reload: PropTypes.func.isRequired,
};
