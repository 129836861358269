import { getSwitchChangeSuccessMsg } from "@/components/DesignSystem/SwitchAsync/helpers";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { noop } from "lodash";
import { tap } from "lodash/fp";

export const useMappersListQuery = ({ isvConnectionId, reloadToken }) => {
    const { isvMappingService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(isvConnectionId)
                ? pendingPromise()
                : isvMappingService.listMappers(isvConnectionId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadToken],
    );
};
export const useDeleteMapperMutation = ({ afterSuccess = noop }) => {
    const { isvMappingService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            isvMappingService
                .deleteMapper(record.id)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, isvMappingService],
        ({ name }) => t("general.message.deleted", { type: "mapper", name }),
        getErrorMessage,
    );
};
export const useCreateMapperMutation = ({ afterSuccess = noop }) => {
    const { isvMappingService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async connection =>
            isvMappingService
                .createMapper(connection)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, isvMappingService],
        ({ name }) => t("general.message.created", { type: "mapper", name }),
        getErrorMessage,
    );
};
export const useMapper = ({ isvMapperId }) => {
    const { isvMappingService } = useDic();

    return useQueryLoadable(
        () => isvMappingService.getMapper(isvMapperId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isvMapperId],
    );
};

export const useEditMapperMutation = ({ isvMapperId, afterSuccess }) => {
    const { isvMappingService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async mapper =>
            isvMappingService
                .editMapper(isvMapperId, mapper)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, isvMapperId, isvMappingService],

        ({ name }) => t("general.saved", { type: "mapper", name }),
        getErrorMessage,
    );
};

export const useChangeISVMappingStatusMutation = ({
    afterSuccess = noop,
} = {}) => {
    const { isvMappingService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ connectionId, enabled }) =>
            isvMappingService
                .changeStatus(connectionId, enabled)

                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, isvMappingService],
        getSwitchChangeSuccessMsg,
    );
};
